import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import { supabase } from '@/lib/auth';
import { ProductSearch } from './ProductSearch';
import { Plus, Trash2 } from 'lucide-react';
import { format } from 'date-fns';

const purchaseOrderSchema = z.object({
  supplierId: z.string().min(1, 'Supplier is required'),
  expectedDeliveryDate: z.string().optional(),
  notes: z.string().optional(),
});

interface PurchaseFormProps {
  onSubmit: (data: any) => Promise<void>;
  onCancel: () => void;
}

export function PurchaseForm({ onSubmit, onCancel }: PurchaseFormProps) {
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(purchaseOrderSchema),
  });

  // Fetch suppliers on component mount
  useState(() => {
    const fetchSuppliers = async () => {
      try {
        setIsLoading(true);
        const { data, error } = await supabase
          .from('suppliers')
          .select('*')
          .order('name');

        if (error) throw error;
        setSuppliers(data || []);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSuppliers();
  }, []);

  const handleAddProduct = (product: any) => {
    setItems([...items, { 
      productId: product.id,
      product,
      quantity: 1,
      price: product.price || 0,
      extendedPrice: product.price || 0
    }]);
  };

  const handleRemoveItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleQuantityChange = (index: number, value: number) => {
    const newItems = [...items];
    newItems[index].quantity = value;
    newItems[index].extendedPrice = value * newItems[index].price;
    setItems(newItems);
  };

  const handlePriceChange = (index: number, value: number) => {
    const newItems = [...items];
    newItems[index].price = value;
    newItems[index].extendedPrice = value * newItems[index].quantity;
    setItems(newItems);
  };

  const calculateTotal = () => {
    return items.reduce((sum, item) => sum + item.extendedPrice, 0);
  };

  const handleFormSubmit = async (formData: any) => {
    if (items.length === 0) {
      setError('Please add at least one product');
      return;
    }

    await onSubmit({
      ...formData,
      items: items.map(item => ({
        productId: item.productId,
        quantity: item.quantity,
        price: item.price
      }))
    });
  };

  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-6">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-md">
          {error}
        </div>
      )}

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Supplier
          </label>
          <select
            {...register('supplierId')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          >
            <option value="">Select a supplier</option>
            {suppliers.map((supplier) => (
              <option key={supplier.id} value={supplier.id}>
                {supplier.name}
              </option>
            ))}
          </select>
          {errors.supplierId && (
            <p className="mt-1 text-sm text-red-600">{errors.supplierId.message?.toString()}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Expected Delivery Date
          </label>
          <input
            type="date"
            {...register('expectedDeliveryDate')}
            min={format(new Date(), 'yyyy-MM-dd')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Products
        </label>
        <ProductSearch 
          onSelect={handleAddProduct}
          excludeIds={items.map(item => item.productId)}
        />

        <div className="mt-4 space-y-4">
          {items.map((item, index) => (
            <div key={index} className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg">
              <div className="flex-1">
                <div className="font-medium">{item.product.name}</div>
                <div className="text-sm text-gray-500">SKU: {item.product.sku}</div>
              </div>
              
              <div className="w-24">
                <input
                  type="number"
                  min="1"
                  value={item.quantity}
                  onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Qty"
                />
              </div>

              <div className="w-32">
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  value={item.price}
                  onChange={(e) => handlePriceChange(index, parseFloat(e.target.value))}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Price"
                />
              </div>

              <div className="w-32">
                <input
                  type="number"
                  value={item.extendedPrice.toFixed(2)}
                  readOnly
                  className="block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm"
                  placeholder="Extended"
                />
              </div>

              <Button
                type="button"
                variant="ghost"
                size="icon"
                onClick={() => handleRemoveItem(index)}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          ))}

          {items.length > 0 && (
            <div className="flex justify-end items-center pt-4 border-t border-gray-200">
              <div className="text-lg font-semibold">
                Total: ${calculateTotal().toFixed(2)}
              </div>
            </div>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Notes
        </label>
        <textarea
          {...register('notes')}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        />
      </div>

      <div className="flex justify-end space-x-3">
        <Button type="button" variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit">
          Create Purchase Order
        </Button>
      </div>
    </form>
  );
}