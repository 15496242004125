import { Dialog } from '@/components/ui/dialog';
import { format } from 'date-fns';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';

interface EventModalProps {
  event: any;
  isOpen: boolean;
  onClose: () => void;
}

export function EventModal({ event, isOpen, onClose }: EventModalProps) {
  const navigate = useNavigate();

  if (!event) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg p-6 w-full max-w-md">
          <h2 className="text-2xl font-bold mb-4">{event.title}</h2>
          
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold text-gray-700">Customer</h3>
              <p>{event.customer.name}</p>
            </div>

            <div>
              <h3 className="font-semibold text-gray-700">Time</h3>
              <p>
                {format(new Date(event.start), 'PPp')} -{' '}
                {event.end && format(new Date(event.end), 'PPp')}
              </p>
            </div>

            <div>
              <h3 className="font-semibold text-gray-700">Assigned Users</h3>
              <ul className="list-disc list-inside">
                {event.assignedUsers.map((user: any) => (
                  <li key={user.id}>
                    {user.firstName} {user.lastName}
                  </li>
                ))}
              </ul>
            </div>

            {event.description && (
              <div>
                <h3 className="font-semibold text-gray-700">Description</h3>
                <p className="whitespace-pre-wrap">{event.description}</p>
              </div>
            )}
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <Button
              variant="outline"
              onClick={() => navigate(`/jobs/${event.id}`)}
            >
              View Job Details
            </Button>
            <Button onClick={onClose}>Close</Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}