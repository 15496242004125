import { ReactNode, useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './sidebar';
import { Header } from './header';

export function Layout() {
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobileView(window.innerWidth < 768);
      setIsSidebarOpen(window.innerWidth >= 768);
    };

    // Initial check
    checkScreenSize();

    // Add resize listener
    window.addEventListener('resize', checkScreenSize);

    // Cleanup
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      <Header 
        onMenuClick={() => setIsSidebarOpen(!isSidebarOpen)}
        isMobileView={isMobileView}
      />
      <div className="flex">
        {/* Sidebar with mobile responsive behavior */}
        <div className={`
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
          ${isMobileView ? 'fixed inset-y-0 z-50' : 'relative'}
          transition-transform duration-300 ease-in-out
        `}>
          <Sidebar />
          {isMobileView && isSidebarOpen && (
            <div 
              className="fixed inset-0 bg-black bg-opacity-50 z-40"
              onClick={() => setIsSidebarOpen(false)}
            />
          )}
        </div>

        {/* Main content */}
        <main className={`
          flex-1 p-6
          ${isMobileView ? 'w-full' : ''}
        `}>
          <Outlet />
        </main>
      </div>
    </div>
  );
}