import { useState, useCallback } from 'react';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { trpc } from '@/utils/trpc';
import { EventModal } from './EventModal';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar as BigCalendar, dateFnsLocalizer, Views } from 'react-big-calendar';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export function Calendar() {
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [dateRange, setDateRange] = useState({
    start: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    end: new Date(new Date().setMonth(new Date().getMonth() + 1)),
  });

  const { data: events = [] } = trpc.schedule.getEvents.useQuery({
    start: dateRange.start.toISOString(),
    end: dateRange.end.toISOString(),
  });

  const updateEventDates = trpc.schedule.updateEventDates.useMutation();

  const handleRangeChange = useCallback((range: any) => {
    if (Array.isArray(range)) {
      setDateRange({
        start: range[0],
        end: range[range.length - 1],
      });
    } else {
      setDateRange({
        start: range.start,
        end: range.end,
      });
    }
  }, []);

  const handleEventResize = useCallback(
    ({ event, start, end }: any) => {
      updateEventDates.mutate({
        id: event.id,
        start: start.toISOString(),
        end: end.toISOString(),
      });
    },
    [updateEventDates]
  );

  const handleEventDrop = useCallback(
    ({ event, start, end }: any) => {
      updateEventDates.mutate({
        id: event.id,
        start: start.toISOString(),
        end: end.toISOString(),
      });
    },
    [updateEventDates]
  );

  return (
    <>
      <div className="h-[calc(100vh-12rem)] bg-white rounded-lg shadow p-4">
        <BigCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          onRangeChange={handleRangeChange}
          onSelectEvent={setSelectedEvent}
          onEventResize={handleEventResize}
          onEventDrop={handleEventDrop}
          defaultView={Views.WEEK}
          views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
          resizable
          selectable
          popup
          className="h-full"
        />
      </div>

      {selectedEvent && (
        <EventModal
          event={selectedEvent}
          isOpen={!!selectedEvent}
          onClose={() => setSelectedEvent(null)}
        />
      )}
    </>
  );
}