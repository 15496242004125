import { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { OrganizationSettings } from './components/OrganizationSettings';
import { IntegrationsSettings } from './components/IntegrationsSettings';
import { ApiSettings } from './components/ApiSettings';
import { UserManagement } from './components/UserManagement';

export function Settings() {
  const [activeTab, setActiveTab] = useState('organization');

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Settings</h1>

      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList>
          <TabsTrigger value="organization">Organization</TabsTrigger>
          <TabsTrigger value="users">Users</TabsTrigger>
          <TabsTrigger value="integrations">Integrations</TabsTrigger>
          <TabsTrigger value="api">API</TabsTrigger>
        </TabsList>

        <TabsContent value="organization" className="mt-6">
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-6">Organization Settings</h2>
            <OrganizationSettings />
          </div>
        </TabsContent>

        <TabsContent value="users" className="mt-6">
          <div className="bg-white rounded-lg shadow p-6">
            <UserManagement />
          </div>
        </TabsContent>

        <TabsContent value="integrations" className="mt-6">
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-6">External Integrations</h2>
            <IntegrationsSettings />
          </div>
        </TabsContent>

        <TabsContent value="api" className="mt-6">
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-6">API Settings</h2>
            <ApiSettings />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
}