import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { CustomerList } from './components/CustomerList';
import { CustomerForm } from './components/CustomerForm';
import { supabase } from '@/lib/auth';

export function Customers() {
  const [isCreating, setIsCreating] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (data: any) => {
    try {
      setError(null);
      
      // Get the current user's session
      const { data: { user }, error: authError } = await supabase.auth.getUser();
      if (authError) throw authError;
      if (!user) throw new Error('No authenticated user');

      // Add organization_id to the customer data
      const customerData = {
        ...data,
        organization_id: user.organization_id, // previously user.user_metadata.organization_id
        // user_id: user.id
      };
      console.log("Customer Data to Insert:", customerData);
      console.log("User Data:", user);
      
      if (editingCustomer) {
        const { error: updateError } = await supabase
          .from('customers')
          .update(customerData)
          .eq('id', editingCustomer.id);

        if (updateError) throw updateError;
      } else {
        const { error: insertError } = await supabase
          .from('customers')
          .insert([customerData]);

        if (insertError) throw insertError;
      }

      setIsCreating(false);
      setEditingCustomer(null);
    } catch (err: any) {
      console.error('Failed to save customer:', err);
      setError(err.message || 'Failed to save customer');
    }
  };

  const handleEdit = (customer: any) => {
    setEditingCustomer(customer);
    setIsCreating(true);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Customers</h1>
        <Button onClick={() => {
          setEditingCustomer(null);
          setIsCreating(true);
        }}>
          Add Customer
        </Button>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-md">
          {error}
        </div>
      )}

      {isCreating ? (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">
            {editingCustomer ? 'Edit Customer' : 'Add New Customer'}
          </h2>
          <CustomerForm
            initialData={editingCustomer}
            onSubmit={handleSubmit}
            onCancel={() => {
              setIsCreating(false);
              setEditingCustomer(null);
              setError(null);
            }}
          />
        </div>
      ) : (
        <CustomerList onEdit={handleEdit} />
      )}
    </div>
  );
}