import { Customer } from '@prisma/client';
import { Button } from '@/components/ui/button';
import { Edit, Trash2, User, Mail, Phone, MapPin, Tag } from 'lucide-react';

interface CustomerCardProps {
  customer: Customer & { pricingGroup?: { name: string } | null };
  onEdit: (customer: Customer) => void;
  onDelete: (id: string) => void;
}

export function CustomerCard({ customer, onEdit, onDelete }: CustomerCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6 space-y-4">
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-blue-50 rounded-lg">
            <User className="h-6 w-6 text-blue-600" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-900">
              {customer.name}
            </h3>
            {customer.pricingGroup && (
              <div className="flex items-center mt-1">
                <Tag className="h-4 w-4 text-gray-400 mr-1" />
                <span className="text-sm text-gray-500">
                  {customer.pricingGroup.name}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="flex space-x-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onEdit(customer)}
          >
            <Edit className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onDelete(customer.id)}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <div className="space-y-2">
        {customer.email && (
          <div className="flex items-center space-x-2 text-sm text-gray-500">
            <Mail className="h-4 w-4" />
            <span>{customer.email}</span>
          </div>
        )}
        {customer.phone && (
          <div className="flex items-center space-x-2 text-sm text-gray-500">
            <Phone className="h-4 w-4" />
            <span>{customer.phone}</span>
          </div>
        )}
        {customer.address && (
          <div className="flex items-center space-x-2 text-sm text-gray-500">
            <MapPin className="h-4 w-4" />
            <span>{customer.address}</span>
          </div>
        )}
      </div>

      {customer.notes && (
        <div className="pt-4 border-t border-gray-200">
          <p className="text-sm text-gray-500">{customer.notes}</p>
        </div>
      )}
    </div>
  );
}