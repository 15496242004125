import { Calendar } from './components/Calendar';

export function Schedule() {
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Schedule</h1>
      </div>

      <Calendar />
    </div>
  );
}