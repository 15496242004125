import { useState } from 'react';
import { trpc } from '@/utils/trpc';
import { Button } from '@/components/ui/button';
import { AlertTriangle, Check, X, RefreshCw } from 'lucide-react';

interface Integration {
  id: string;
  type: 'QUICKBOOKS' | 'SAGE';
  config: Record<string, string>;
  enabled: boolean;
}

export function IntegrationsSettings() {
  const { data: integrations, isLoading } = trpc.settings.getIntegrations.useQuery();
  const updateMutation = trpc.settings.updateIntegration.useMutation();
  const testMutation = trpc.settings.testIntegration.useMutation();
  const utils = trpc.useContext();

  const [editingId, setEditingId] = useState<string | null>(null);
  const [testingId, setTestingId] = useState<string | null>(null);

  const handleTestConnection = async (integration: Integration) => {
    try {
      setTestingId(integration.id);
      await testMutation.mutateAsync({ id: integration.id });
      // Show success message
    } catch (error) {
      // Show error message
    } finally {
      setTestingId(null);
    }
  };

  const handleToggleEnabled = async (integration: Integration) => {
    try {
      await updateMutation.mutateAsync({
        id: integration.id,
        data: {
          ...integration,
          enabled: !integration.enabled,
        },
      });
      utils.settings.getIntegrations.invalidate();
    } catch (error) {
      console.error('Failed to update integration:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <ul className="divide-y divide-gray-200">
          {integrations?.map((integration) => (
            <li key={integration.id} className="px-4 py-4">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    {integration.type}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Status: {integration.enabled ? 'Enabled' : 'Disabled'}
                  </p>
                </div>

                <div className="flex items-center space-x-2">
                  <Button
                    variant="outline"
                    onClick={() => handleTestConnection(integration)}
                    disabled={!integration.enabled || testingId === integration.id}
                  >
                    {testingId === integration.id ? (
                      <RefreshCw className="h-4 w-4 mr-2 animate-spin" />
                    ) : (
                      'Test Connection'
                    )}
                  </Button>

                  <Button
                    variant={integration.enabled ? 'destructive' : 'default'}
                    onClick={() => handleToggleEnabled(integration)}
                  >
                    {integration.enabled ? (
                      <>
                        <X className="h-4 w-4 mr-2" />
                        Disable
                      </>
                    ) : (
                      <>
                        <Check className="h-4 w-4 mr-2" />
                        Enable
                      </>
                    )}
                  </Button>
                </div>
              </div>

              {editingId === integration.id ? (
                <div className="mt-4 space-y-4">
                  {Object.entries(integration.config).map(([key, value]) => (
                    <div key={key}>
                      <label className="block text-sm font-medium text-gray-700">
                        {key}
                      </label>
                      <input
                        type="text"
                        value={value}
                        onChange={(e) => {
                          updateMutation.mutate({
                            id: integration.id,
                            data: {
                              ...integration,
                              config: {
                                ...integration.config,
                                [key]: e.target.value,
                              },
                            },
                          });
                        }}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                  ))}

                  <Button
                    variant="outline"
                    onClick={() => setEditingId(null)}
                  >
                    Done
                  </Button>
                </div>
              ) : (
                <Button
                  variant="ghost"
                  className="mt-2"
                  onClick={() => setEditingId(integration.id)}
                >
                  Configure
                </Button>
              )}

              {!integration.enabled && (
                <div className="mt-2 flex items-center text-sm text-yellow-600">
                  <AlertTriangle className="h-4 w-4 mr-1" />
                  This integration is currently disabled
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}