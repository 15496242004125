import { useState } from 'react';
import { trpc } from '@/utils/trpc';
import { format } from 'date-fns';
import { ArrowRight } from 'lucide-react';

export function TransferHistory() {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString(),
    endDate: new Date().toISOString(),
  });

  const { data: transfers, isLoading } = trpc.inventory.getTransferHistory.useQuery({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Transfer History</h3>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <ul className="divide-y divide-gray-200">
          {transfers?.map((transfer) => (
            <li key={transfer.id} className="px-4 py-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-900">
                    {transfer.product.name}
                  </p>
                  <p className="text-sm text-gray-500">
                    Quantity: {transfer.quantity}
                  </p>
                </div>

                <div className="flex items-center space-x-4">
                  <div className="text-right">
                    <p className="text-sm text-gray-900">
                      {transfer.fromWarehouse?.name || transfer.fromVehicle?.name}
                    </p>
                  </div>
                  <ArrowRight className="h-4 w-4 text-gray-400" />
                  <div>
                    <p className="text-sm text-gray-900">
                      {transfer.toWarehouse?.name || transfer.toVehicle?.name}
                    </p>
                  </div>
                </div>

                <div className="text-right">
                  <p className="text-sm text-gray-500">
                    {format(new Date(transfer.createdAt), 'PPp')}
                  </p>
                </div>
              </div>

              {transfer.notes && (
                <p className="mt-2 text-sm text-gray-500">{transfer.notes}</p>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}