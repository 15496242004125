import { trpc } from '@/utils/trpc';
import { Button } from '@/components/ui/button';
import { Edit, Trash2, Package } from 'lucide-react';

export function WarehouseList() {
  const { data: warehouses, isLoading } = trpc.warehouses.list.useQuery();
  const deleteMutation = trpc.warehouses.delete.useMutation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {warehouses?.map((warehouse) => (
        <div
          key={warehouse.id}
          className="bg-white rounded-lg shadow-sm p-6 space-y-4"
        >
          <div className="flex items-start justify-between">
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-blue-50 rounded-lg">
                <Package className="h-6 w-6 text-blue-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">
                  {warehouse.name}
                </h3>
                {warehouse.address && (
                  <p className="text-sm text-gray-500">{warehouse.address}</p>
                )}
              </div>
            </div>
            <div className="flex space-x-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {/* TODO: Implement edit */}}
              >
                <Edit className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => deleteMutation.mutate({ id: warehouse.id })}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          </div>

          <div className="pt-4 border-t border-gray-200">
            <Button
              variant="outline"
              className="w-full"
              onClick={() => {/* TODO: View inventory */}}
            >
              View Inventory
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}