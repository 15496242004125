import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import { trpc } from '@/utils/trpc';
import { Plus, Trash2 } from 'lucide-react';

const lineItemSchema = z.object({
  productId: z.string(),
  quantity: z.number().min(1),
  price: z.number().min(0),
  description: z.string().optional(),
});

const estimateSchema = z.object({
  customerId: z.string(),
  items: z.array(lineItemSchema),
  notes: z.string().optional(),
  validUntil: z.string().optional(),
});

type EstimateFormData = z.infer<typeof estimateSchema>;

interface EstimateFormProps {
  initialData?: EstimateFormData;
  onSubmit: (data: EstimateFormData) => void;
}

export function EstimateForm({ initialData, onSubmit }: EstimateFormProps) {
  const { data: customers } = trpc.customers.list.useQuery();
  const { data: products } = trpc.products.list.useQuery();

  const { register, control, handleSubmit, watch, formState: { errors } } = useForm<EstimateFormData>({
    resolver: zodResolver(estimateSchema),
    defaultValues: initialData || {
      items: [{ productId: '', quantity: 1, price: 0 }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const items = watch('items');
  const total = items?.reduce((sum, item) => sum + (item.quantity || 0) * (item.price || 0), 0) || 0;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div>
        <label htmlFor="customerId" className="block text-sm font-medium text-gray-700">
          Customer
        </label>
        <select
          {...register('customerId')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        >
          <option value="">Select a customer</option>
          {customers?.map((customer) => (
            <option key={customer.id} value={customer.id}>
              {customer.name}
            </option>
          ))}
        </select>
        {errors.customerId && (
          <p className="mt-1 text-sm text-red-600">{errors.customerId.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Line Items
        </label>
        <div className="space-y-4">
          {fields.map((field, index) => (
            <div key={field.id} className="flex gap-4 items-start">
              <div className="flex-1">
                <select
                  {...register(`items.${index}.productId`)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value="">Select a product</option>
                  {products?.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className="w-24">
                <input
                  type="number"
                  {...register(`items.${index}.quantity`)}
                  placeholder="Qty"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              
              <div className="w-32">
                <input
                  type="number"
                  step="0.01"
                  {...register(`items.${index}.price`)}
                  placeholder="Price"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>

              <Button
                type="button"
                variant="ghost"
                size="icon"
                onClick={() => remove(index)}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          ))}
        </div>

        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={() => append({ productId: '', quantity: 1, price: 0 })}
          className="mt-2"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Item
        </Button>
      </div>

      <div>
        <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
          Notes
        </label>
        <textarea
          {...register('notes')}
          rows={4}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        />
      </div>

      <div>
        <label htmlFor="validUntil" className="block text-sm font-medium text-gray-700">
          Valid Until
        </label>
        <input
          type="date"
          {...register('validUntil')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        />
      </div>

      <div className="flex justify-between items-center pt-4 border-t">
        <div className="text-lg font-semibold">
          Total: ${total.toFixed(2)}
        </div>
        <Button type="submit">
          {initialData ? 'Update Estimate' : 'Create Estimate'}
        </Button>
      </div>
    </form>
  );
}