import { trpc } from '@/utils/trpc';
import { Button } from '@/components/ui/button';
import { Edit, Trash2, Tag } from 'lucide-react';

interface PricingGroupListProps {
  onEdit: (group: any) => void;
}

export function PricingGroupList({ onEdit }: PricingGroupListProps) {
  const { data: groups, isLoading } = trpc.pricingGroups.list.useQuery();
  const deleteMutation = trpc.pricingGroups.delete.useMutation();
  const utils = trpc.useContext();

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync({ id });
      utils.pricingGroups.list.invalidate();
    } catch (error) {
      console.error('Failed to delete pricing group:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {groups?.map((group) => (
        <div key={group.id} className="bg-white rounded-lg shadow-sm p-6 space-y-4">
          <div className="flex items-start justify-between">
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-purple-50 rounded-lg">
                <Tag className="h-6 w-6 text-purple-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">
                  {group.name}
                </h3>
                {group.description && (
                  <p className="text-sm text-gray-500">{group.description}</p>
                )}
              </div>
            </div>
            <div className="flex space-x-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => onEdit(group)}
              >
                <Edit className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleDelete(group.id)}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          </div>

          <div className="pt-4 border-t border-gray-200">
            <h4 className="text-sm font-medium text-gray-500 mb-2">Pricing Rules</h4>
            <div className="space-y-2">
              {group.rules.map((rule) => (
                <div key={rule.id} className="flex justify-between text-sm">
                  <span className="text-gray-600">{rule.product.name}</span>
                  <span className="font-medium">
                    {rule.type === 'FIXED' && `$${rule.value.toFixed(2)}`}
                    {rule.type === 'MARKUP_PERCENTAGE' && `+${rule.value}%`}
                    {rule.type === 'MARKUP_AMOUNT' && `+$${rule.value.toFixed(2)}`}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}