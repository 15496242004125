import { trpc } from '@/utils/trpc';
import { EquipmentCard } from './EquipmentCard';

export function EquipmentList({
  onEdit,
}: {
  onEdit: (equipment: any) => void;
}) {
  const { data: equipment, isLoading } = trpc.equipment.list.useQuery();
  const deleteMutation = trpc.equipment.delete.useMutation();
  const updateStatusMutation = trpc.equipment.updateStatus.useMutation();
  const utils = trpc.useContext();

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync({ id });
      utils.equipment.list.invalidate();
    } catch (error) {
      console.error('Failed to delete equipment:', error);
    }
  };

  const handleStatusChange = async (id: string, status: any) => {
    try {
      await updateStatusMutation.mutateAsync({ id, status });
      utils.equipment.list.invalidate();
    } catch (error) {
      console.error('Failed to update equipment status:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {equipment?.map((item) => (
        <EquipmentCard
          key={item.id}
          equipment={item}
          onEdit={onEdit}
          onDelete={handleDelete}
          onStatusChange={handleStatusChange}
        />
      ))}
    </div>
  );
}