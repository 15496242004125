import { useEffect, useState } from 'react';
import { trpc } from '@/utils/trpc';
import { Button } from '@/components/ui/button';
import { Play, Pause, Clock } from 'lucide-react';
import { format } from 'date-fns';

export function TimeTracker() {
  const [elapsedTime, setElapsedTime] = useState(0);
  const utils = trpc.useContext();
  
  const { data: currentEntry, isLoading } = trpc.timeTracking.getCurrentEntry.useQuery();
  const { data: jobs } = trpc.jobs.list.useQuery();
  
  const clockInMutation = trpc.timeTracking.clockIn.useMutation({
    onSuccess: () => utils.timeTracking.getCurrentEntry.invalidate(),
  });
  
  const clockOutMutation = trpc.timeTracking.clockOut.useMutation({
    onSuccess: () => utils.timeTracking.getCurrentEntry.invalidate(),
  });

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (currentEntry) {
      interval = setInterval(() => {
        const start = new Date(currentEntry.startTime).getTime();
        const now = new Date().getTime();
        setElapsedTime(now - start);
      }, 1000);
    } else {
      setElapsedTime(0);
    }

    return () => clearInterval(interval);
  }, [currentEntry]);

  const formatElapsedTime = (ms: number) => {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor(ms / (1000 * 60 * 60));

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleClockIn = async () => {
    await clockInMutation.mutate({
      startTime: new Date().toISOString(),
    });
  };

  const handleClockOut = async () => {
    if (!currentEntry) return;
    
    await clockOutMutation.mutate({
      id: currentEntry.id,
      endTime: new Date().toISOString(),
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-4">
          <Clock className="h-8 w-8 text-gray-400" />
          <div>
            <h2 className="text-2xl font-bold">
              {formatElapsedTime(elapsedTime)}
            </h2>
            {currentEntry && (
              <p className="text-sm text-gray-500">
                Started at {format(new Date(currentEntry.startTime), 'h:mm a')}
              </p>
            )}
          </div>
        </div>

        {currentEntry ? (
          <Button
            onClick={handleClockOut}
            className="bg-red-500 hover:bg-red-600"
          >
            <Pause className="h-4 w-4 mr-2" />
            Clock Out
          </Button>
        ) : (
          <Button onClick={handleClockIn}>
            <Play className="h-4 w-4 mr-2" />
            Clock In
          </Button>
        )}
      </div>

      {currentEntry && (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Current Job
            </label>
            <select
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              value={currentEntry.jobId || ''}
              onChange={(e) => {
                // TODO: Implement job assignment
              }}
            >
              <option value="">No job assigned</option>
              {jobs?.map((job) => (
                <option key={job.id} value={job.id}>
                  {job.title}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Notes
            </label>
            <textarea
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              rows={3}
              value={currentEntry.notes || ''}
              onChange={(e) => {
                // TODO: Implement notes update
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}