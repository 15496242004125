import { trpc } from '@/utils/trpc';
import { SupplierCard } from './SupplierCard';

interface SupplierListProps {
  onEdit: (supplier: any) => void;
}

export function SupplierList({ onEdit }: SupplierListProps) {
  const { data: suppliers, isLoading } = trpc.suppliers.list.useQuery();
  const deleteMutation = trpc.suppliers.delete.useMutation();
  const utils = trpc.useContext();

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync({ id });
      utils.suppliers.list.invalidate();
    } catch (error) {
      console.error('Failed to delete supplier:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {suppliers?.map((supplier) => (
        <SupplierCard
          key={supplier.id}
          supplier={supplier}
          onEdit={onEdit}
          onDelete={handleDelete}
        />
      ))}
    </div>
  );
}