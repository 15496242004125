import { Supplier } from '@prisma/client';
import { Button } from '@/components/ui/button';
import { Edit, Trash2, Building2, Mail, Phone, MapPin, User } from 'lucide-react';

interface SupplierCardProps {
  supplier: Supplier;
  onEdit: (supplier: Supplier) => void;
  onDelete: (id: string) => void;
}

export function SupplierCard({ supplier, onEdit, onDelete }: SupplierCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6 space-y-4">
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-orange-50 rounded-lg">
            <Building2 className="h-6 w-6 text-orange-600" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-900">
              {supplier.name}
            </h3>
            {supplier.contactPerson && (
              <div className="flex items-center mt-1">
                <User className="h-4 w-4 text-gray-400 mr-1" />
                <span className="text-sm text-gray-500">
                  {supplier.contactPerson}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="flex space-x-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onEdit(supplier)}
          >
            <Edit className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onDelete(supplier.id)}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <div className="space-y-2">
        {supplier.email && (
          <div className="flex items-center space-x-2 text-sm text-gray-500">
            <Mail className="h-4 w-4" />
            <span>{supplier.email}</span>
          </div>
        )}
        {supplier.phone && (
          <div className="flex items-center space-x-2 text-sm text-gray-500">
            <Phone className="h-4 w-4" />
            <span>{supplier.phone}</span>
          </div>
        )}
        {supplier.address && (
          <div className="flex items-center space-x-2 text-sm text-gray-500">
            <MapPin className="h-4 w-4" />
            <span>{supplier.address}</span>
          </div>
        )}
      </div>

      {supplier.notes && (
        <div className="pt-4 border-t border-gray-200">
          <p className="text-sm text-gray-500">{supplier.notes}</p>
        </div>
      )}
    </div>
  );
}