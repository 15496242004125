import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { EstimateList } from './components/EstimateList';
import { EstimateForm } from './components/EstimateForm';

export function Estimates() {
  const [isCreating, setIsCreating] = useState(false);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Estimates</h1>
        <Button onClick={() => setIsCreating(true)}>Create Estimate</Button>
      </div>

      {isCreating ? (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Create New Estimate</h2>
          <EstimateForm
            onSubmit={async (data) => {
              // TODO: Implement estimate creation
              setIsCreating(false);
            }}
          />
        </div>
      ) : (
        <EstimateList />
      )}
    </div>
  );
}