import { useState } from 'react';
import { InventoryList } from './components/InventoryList';
import { TransferHistory } from './components/TransferHistory';
import { TransferModal } from './components/TransferModal';

export function Inventory() {
  const [selectedItem, setSelectedItem] = useState<any>(null);

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Inventory Management</h1>

      <InventoryList />
      
      <TransferHistory />

      {selectedItem && (
        <TransferModal
          isOpen={!!selectedItem}
          onClose={() => setSelectedItem(null)}
          inventoryItem={selectedItem}
        />
      )}
    </div>
  );
}