import { useState } from 'react';
import { trpc } from '@/utils/trpc';
import { Button } from '@/components/ui/button';
import { 
  Mail, Database, Key, Bell, 
  Shield, Globe, Server 
} from 'lucide-react';

export function SystemSettingsPage() {
  const { data: settings } = trpc.admin.settings.get.useQuery();
  const updateMutation = trpc.admin.settings.update.useMutation();

  const handleSave = async (section: string, data: any) => {
    try {
      await updateMutation.mutateAsync({ section, data });
    } catch (error) {
      console.error('Failed to update settings:', error);
    }
  };

  return (
    <div className="space-y-6">
      {/* Email Settings */}
      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex items-center">
          <Mail className="h-5 w-5 text-gray-400 mr-3" />
          <h3 className="text-lg font-medium text-gray-900">
            Email Settings
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                SMTP Host
              </label>
              <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={settings?.email.host}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                SMTP Port
              </label>
              <input
                type="number"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={settings?.email.port}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Database Settings */}
      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex items-center">
          <Database className="h-5 w-5 text-gray-400 mr-3" />
          <h3 className="text-lg font-medium text-gray-900">
            Database Settings
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Max Connections Per Tenant
              </label>
              <input
                type="number"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={settings?.database.maxConnections}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Backup Schedule
              </label>
              <select
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={settings?.database.backupSchedule}
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {/* Security Settings */}
      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex items-center">
          <Shield className="h-5 w-5 text-gray-400 mr-3" />
          <h3 className="text-lg font-medium text-gray-900">
            Security Settings
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                defaultChecked={settings?.security.twoFactorEnabled}
              />
              <label className="ml-2 block text-sm text-gray-900">
                Require Two-Factor Authentication
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                defaultChecked={settings?.security.strongPasswords}
              />
              <label className="ml-2 block text-sm text-gray-900">
                Enforce Strong Password Policy
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* API Settings */}
      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex items-center">
          <Key className="h-5 w-5 text-gray-400 mr-3" />
          <h3 className="text-lg font-medium text-gray-900">
            API Settings
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Rate Limit (requests per minute)
              </label>
              <input
                type="number"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={settings?.api.rateLimit}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Token Expiration (hours)
              </label>
              <input
                type="number"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={settings?.api.tokenExpiration}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <Button>Save Changes</Button>
      </div>
    </div>
  );
}