import { useState } from 'react';
import { Dialog } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { trpc } from '@/utils/trpc';

interface TransferModalProps {
  isOpen: boolean;
  onClose: () => void;
  inventoryItem: any;
}

export function TransferModal({ isOpen, onClose, inventoryItem }: TransferModalProps) {
  const [quantity, setQuantity] = useState(1);
  const [destination, setDestination] = useState('');
  const [notes, setNotes] = useState('');

  const { data: warehouses } = trpc.warehouses.list.useQuery();
  const { data: vehicles } = trpc.vehicles.list.useQuery();
  const transferMutation = trpc.inventory.transferInventory.useMutation();

  const handleTransfer = async () => {
    const [type, id] = destination.split(':');
    
    await transferMutation.mutate({
      productId: inventoryItem.productId,
      quantity,
      fromWarehouseId: inventoryItem.warehouseId,
      fromVehicleId: inventoryItem.vehicleId,
      toWarehouseId: type === 'warehouse' ? id : undefined,
      toVehicleId: type === 'vehicle' ? id : undefined,
      notes,
    });

    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg p-6 w-full max-w-md">
          <h2 className="text-lg font-semibold mb-4">
            Transfer {inventoryItem?.product.name}
          </h2>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Quantity
              </label>
              <input
                type="number"
                min="1"
                max={inventoryItem?.quantity}
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Destination
              </label>
              <select
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="">Select destination</option>
                <optgroup label="Warehouses">
                  {warehouses?.map((warehouse) => (
                    <option
                      key={warehouse.id}
                      value={`warehouse:${warehouse.id}`}
                      disabled={warehouse.id === inventoryItem?.warehouseId}
                    >
                      {warehouse.name}
                    </option>
                  ))}
                </optgroup>
                <optgroup label="Vehicles">
                  {vehicles?.map((vehicle) => (
                    <option
                      key={vehicle.id}
                      value={`vehicle:${vehicle.id}`}
                      disabled={vehicle.id === inventoryItem?.vehicleId}
                    >
                      {vehicle.name}
                    </option>
                  ))}
                </optgroup>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Notes
              </label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={handleTransfer}
              disabled={!destination || quantity < 1}
            >
              Transfer
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}