import { trpc } from '@/utils/trpc';
import { JobCard } from './JobCard';
import { supabase } from '@/lib/auth';
import { useEffect, useState } from 'react';

export function JobList() {
  const [jobs, setJobs] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const { data, error } = await supabase
          .from('jobs')
          .select(`
            *,
            customer:customers(*),
            assignedUsers:job_assignments(
              user:users(*)
            )
          `)
          .order('created_at', { ascending: false });

        if (error) throw error;
        setJobs(data || []);
      } catch (error: any) {
        console.error('Error fetching jobs:', error);
        setError(error.message || 'Failed to load jobs');
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobs();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-gray-500">Loading jobs...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-md">
        <p>{error}</p>
        <Button 
          variant="outline" 
          className="mt-2"
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </div>
    );
  }

  if (!jobs?.length) {
    return (
      <div className="bg-white rounded-lg shadow p-6 text-center">
        <p className="text-gray-500">No jobs found. Create your first job to get started.</p>
      </div>
    );
  }

  const handleDelete = async (id: string) => {
    try {
      const { error } = await supabase
        .from('jobs')
        .delete()
        .eq('id', id);

      if (error) throw error;

      // Remove the deleted job from the state
      setJobs(jobs.filter(job => job.id !== id));
    } catch (error: any) {
      console.error('Failed to delete job:', error);
      setError(error.message || 'Failed to delete job');
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {jobs.map((job) => (
        <JobCard
          key={job.id}
          job={{
            ...job,
            assignedUsers: job.assignedUsers?.map((assignment: any) => assignment.user)
          }}
          onDelete={handleDelete}
        />
      ))}
    </div>
  );
}