import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TrpcProvider } from '@/components/providers/TrpcProvider';
import { AuthProvider } from '@/components/providers/AuthProvider';
import AppRoutes from './routes';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <TrpcProvider>
        <Router>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </Router>
      </TrpcProvider>
    </QueryClientProvider>
  );
}

export default App;