import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { trpc } from '@/utils/trpc';
import { PricingGroupForm } from './PricingGroupForm';
import { PricingGroupList } from './PricingGroupList';

export function PricingGroups() {
  const [isCreating, setIsCreating] = useState(false);
  const [editingGroup, setEditingGroup] = useState<any>(null);
  
  const createMutation = trpc.pricingGroups.create.useMutation();
  const updateMutation = trpc.pricingGroups.update.useMutation();
  const utils = trpc.useContext();

  const handleSubmit = async (data: any) => {
    try {
      if (editingGroup) {
        await updateMutation.mutateAsync({
          id: editingGroup.id,
          data,
        });
      } else {
        await createMutation.mutateAsync(data);
      }
      utils.pricingGroups.list.invalidate();
      setIsCreating(false);
      setEditingGroup(null);
    } catch (error) {
      console.error('Failed to save pricing group:', error);
    }
  };

  const handleEdit = (group: any) => {
    setEditingGroup(group);
    setIsCreating(true);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-lg font-medium">Pricing Groups</h2>
          <p className="text-sm text-gray-500">
            Create and manage pricing groups with fixed prices or markup formulas
          </p>
        </div>
        <Button onClick={() => {
          setEditingGroup(null);
          setIsCreating(true);
        }}>
          Create Pricing Group
        </Button>
      </div>

      {isCreating ? (
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4">
            {editingGroup ? 'Edit Pricing Group' : 'Create New Pricing Group'}
          </h3>
          <PricingGroupForm
            initialData={editingGroup}
            onSubmit={handleSubmit}
            onCancel={() => {
              setIsCreating(false);
              setEditingGroup(null);
            }}
          />
        </div>
      ) : (
        <PricingGroupList onEdit={handleEdit} />
      )}
    </div>
  );
}