import { Button } from '@/components/ui/button';
import { Bell, Search, User, Menu } from 'lucide-react';
import { useAuth } from '@/components/providers/AuthProvider';
import { Link, useNavigate } from 'react-router-dom';

interface HeaderProps {
  onMenuClick: () => void;
  isMobileView: boolean;
}

export function Header({ onMenuClick, isMobileView }: HeaderProps) {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <header className="bg-white border-b border-gray-200">
      <div className="flex justify-between items-center px-4 py-4 sm:px-6">
        <div className="flex items-center">
          {isMobileView && (
            <Button
              variant="ghost"
              size="icon"
              onClick={onMenuClick}
              className="mr-4"
            >
              <Menu className="h-6 w-6" />
            </Button>
          )}
          <h1 className="text-xl font-bold text-gray-900">
            Contractor Management
          </h1>
        </div>

        <div className="flex items-center space-x-4">
          {!isMobileView && (
            <>
              <button className="p-1 rounded-full text-gray-400 hover:text-gray-500">
                <Search className="h-6 w-6" />
              </button>
              <button className="p-1 rounded-full text-gray-400 hover:text-gray-500">
                <Bell className="h-6 w-6" />
              </button>
            </>
          )}
          
          <div className="flex items-center space-x-2">
            <Link to="/profile">
              <div className="p-1 rounded-full bg-gray-100 hover:bg-gray-200">
                <User className="h-6 w-6 text-gray-600" />
              </div>
            </Link>
            {!isMobileView && (
              <>
                <div className="text-sm">
                  <Link to="/profile" className="font-medium text-gray-700 hover:text-gray-900">
                    {user?.user_metadata?.first_name} {user?.user_metadata?.last_name}
                  </Link>
                  <p className="text-gray-500">{user?.email}</p>
                </div>
                <Button variant="ghost" onClick={handleLogout}>
                  Logout
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}