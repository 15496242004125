import { useState } from 'react';
import { trpc } from '@/utils/trpc';
import { Button } from '@/components/ui/button';
import { AlertTriangle } from 'lucide-react';

export function InventoryList() {
  const [selectedLocation, setSelectedLocation] = useState<{
    warehouseId?: string;
    vehicleId?: string;
  }>({});

  const { data: inventoryItems, isLoading } = trpc.inventory.getInventoryLevels.useQuery(
    selectedLocation
  );
  const { data: warehouses } = trpc.warehouses.list.useQuery();
  const { data: vehicles } = trpc.vehicles.list.useQuery();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-4">
        <select
          className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          onChange={(e) => {
            const [type, id] = e.target.value.split(':');
            setSelectedLocation(
              type === 'warehouse'
                ? { warehouseId: id }
                : type === 'vehicle'
                ? { vehicleId: id }
                : {}
            );
          }}
        >
          <option value="">All Locations</option>
          <optgroup label="Warehouses">
            {warehouses?.map((warehouse) => (
              <option key={warehouse.id} value={`warehouse:${warehouse.id}`}>
                {warehouse.name}
              </option>
            ))}
          </optgroup>
          <optgroup label="Vehicles">
            {vehicles?.map((vehicle) => (
              <option key={vehicle.id} value={`vehicle:${vehicle.id}`}>
                {vehicle.name}
              </option>
            ))}
          </optgroup>
        </select>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Product
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Location
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Quantity
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {inventoryItems?.map((item) => (
              <tr key={item.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.product.name}
                  </div>
                  <div className="text-sm text-gray-500">
                    {item.product.sku}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {item.warehouse?.name || item.vehicle?.name}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {item.quantity}
                  </div>
                  <div className="text-sm text-gray-500">
                    Min: {item.minimumQuantity}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {item.quantity <= item.minimumQuantity ? (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                      <AlertTriangle className="h-4 w-4 mr-1" />
                      Low Stock
                    </span>
                  ) : (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      In Stock
                    </span>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <Button
                    variant="ghost"
                    className="text-indigo-600 hover:text-indigo-900"
                    onClick={() => {/* TODO: Open transfer modal */}}
                  >
                    Transfer
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}