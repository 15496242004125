import { useEffect, useState } from 'react';
import { supabase } from '@/lib/auth';
import { Briefcase, Clock, FileText, Calendar } from 'lucide-react';

export function ProfileStats() {
  const [stats, setStats] = useState({
    totalJobs: 0,
    totalHours: 0,
    completedEstimates: 0,
    upcomingJobs: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setIsLoading(true);

        // Fetch jobs count
        const { data: jobs, error: jobsError } = await supabase
          .from('jobs')
          .select('id, status', { count: 'exact' });

        if (jobsError) throw jobsError;

        // Fetch time entries
        const { data: timeEntries, error: timeError } = await supabase
          .from('time_entries')
          .select('start_time, end_time');

        if (timeError) throw timeError;

        // Fetch estimates
        const { data: estimates, error: estimatesError } = await supabase
          .from('estimates')
          .select('id, status');

        if (estimatesError) throw estimatesError;

        // Calculate total hours
        const totalHours = timeEntries?.reduce((sum, entry) => {
          if (!entry.end_time) return sum;
          const duration = new Date(entry.end_time).getTime() - new Date(entry.start_time).getTime();
          return sum + (duration / (1000 * 60 * 60));
        }, 0) || 0;

        // Calculate upcoming jobs
        const upcomingJobs = jobs?.filter(job => 
          job.status === 'PENDING' || job.status === 'IN_PROGRESS'
        ).length || 0;

        setStats({
          totalJobs: jobs?.length || 0,
          totalHours: Math.round(totalHours * 10) / 10,
          completedEstimates: estimates?.filter(e => e.status === 'COMPLETED').length || 0,
          upcomingJobs,
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (isLoading) {
    return (
      <div className="w-1/2 bg-white rounded-lg shadow p-6">
        <div className="text-center text-gray-500">Loading statistics...</div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center">
            <div className="p-3 rounded-full bg-blue-100">
              <Briefcase className="h-6 w-6 text-blue-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Total Jobs</p>
              <p className="text-2xl font-semibold">{stats.totalJobs}</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center">
            <div className="p-3 rounded-full bg-green-100">
              <Clock className="h-6 w-6 text-green-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Total Hours</p>
              <p className="text-2xl font-semibold">{stats.totalHours}</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center">
            <div className="p-3 rounded-full bg-purple-100">
              <FileText className="h-6 w-6 text-purple-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Completed Estimates</p>
              <p className="text-2xl font-semibold">{stats.completedEstimates}</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center">
            <div className="p-3 rounded-full bg-yellow-100">
              <Calendar className="h-6 w-6 text-yellow-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Upcoming Jobs</p>
              <p className="text-2xl font-semibold">{stats.upcomingJobs}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Add more detailed statistics or charts here */}
    </div>
  );
}