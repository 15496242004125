import { useState } from 'react';
import { trpc } from '@/utils/trpc';
import { format, startOfWeek, endOfWeek } from 'date-fns';
import { Button } from '@/components/ui/button';
import { Calendar, Clock, Trash2 } from 'lucide-react';

export function TimeEntryList() {
  const [dateRange, setDateRange] = useState({
    startDate: startOfWeek(new Date()).toISOString(),
    endDate: endOfWeek(new Date()).toISOString(),
  });

  const { data: entries, isLoading } = trpc.timeTracking.getEntries.useQuery({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  });

  const deleteMutation = trpc.timeTracking.deleteEntry.useMutation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const calculateDuration = (startTime: Date, endTime?: Date | null) => {
    if (!endTime) return 'In progress';
    const duration = new Date(endTime).getTime() - new Date(startTime).getTime();
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <Calendar className="h-5 w-5 text-gray-400" />
          <select
            className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            onChange={(e) => {
              const date = new Date();
              switch (e.target.value) {
                case 'week':
                  setDateRange({
                    startDate: startOfWeek(date).toISOString(),
                    endDate: endOfWeek(date).toISOString(),
                  });
                  break;
                // Add more range options as needed
              }
            }}
          >
            <option value="week">This Week</option>
            <option value="month">This Month</option>
            <option value="custom">Custom Range</option>
          </select>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {entries?.map((entry) => (
            <li key={entry.id}>
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Clock className="h-5 w-5 text-gray-400 mr-3" />
                    <div>
                      <p className="text-sm font-medium text-gray-900">
                        {format(new Date(entry.startTime), 'PPp')}
                        {entry.endTime && ` - ${format(new Date(entry.endTime), 'p')}`}
                      </p>
                      <p className="text-sm text-gray-500">
                        Duration: {calculateDuration(entry.startTime, entry.endTime)}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    {entry.job && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        {entry.job.title}
                      </span>
                    )}
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => deleteMutation.mutate({ id: entry.id })}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
                {entry.notes && (
                  <p className="mt-2 text-sm text-gray-500">{entry.notes}</p>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}