import { Button } from '@/components/ui/button';
import { Edit, Trash2, MapPin, Clock, User } from 'lucide-react';
import { format } from 'date-fns';

interface JobCardProps {
  job: any;
  onDelete: (id: string) => void;
}

export function JobCard({ job, onDelete }: JobCardProps) {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'PENDING': return 'bg-yellow-100 text-yellow-800';
      case 'IN_PROGRESS': return 'bg-blue-100 text-blue-800';
      case 'COMPLETED': return 'bg-green-100 text-green-800';
      case 'CANCELLED': return 'bg-red-100 text-red-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 space-y-4">
      <div className="flex items-start justify-between">
        <div>
          <h3 className="text-lg font-semibold text-gray-900">{job.title}</h3>
          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(job.status)}`}>
            {job.status}
          </span>
        </div>
        <div className="flex space-x-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => {/* TODO: Implement edit */}}
          >
            <Edit className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onDelete(job.id)}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <div className="space-y-2">
        <div className="flex items-center text-sm text-gray-500">
          <User className="h-4 w-4 mr-2" />
          <span>{job.customer?.name}</span>
        </div>

        <div className="flex items-center text-sm text-gray-500">
          <Clock className="h-4 w-4 mr-2" />
          <span>
            {format(new Date(job.startDate), 'MMM d, yyyy')}
            {job.endDate && ` - ${format(new Date(job.endDate), 'MMM d, yyyy')}`}
          </span>
        </div>

        {job.description && (
          <p className="text-sm text-gray-600">{job.description}</p>
        )}
      </div>

      {job.assignedUsers?.length > 0 && (
        <div className="pt-4 border-t border-gray-200">
          <h4 className="text-sm font-medium text-gray-700 mb-2">Assigned To</h4>
          <div className="flex flex-wrap gap-2">
            {job.assignedUsers.map((user: any) => (
              <span
                key={user.id}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
              >
                {user.firstName} {user.lastName}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}