import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Copy, RefreshCw } from 'lucide-react';

export function ApiSettings() {
  const [apiKey, setApiKey] = useState('sk_test_123456789');
  const [isRegenerating, setIsRegenerating] = useState(false);

  const handleRegenerateKey = async () => {
    setIsRegenerating(true);
    // TODO: Implement API key regeneration
    setTimeout(() => {
      setApiKey('sk_test_' + Math.random().toString(36).substring(7));
      setIsRegenerating(false);
    }, 1000);
  };

  const handleCopyKey = () => {
    navigator.clipboard.writeText(apiKey);
    // TODO: Show success toast
  };

  return (
    <div className="space-y-6">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            API Access
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Use this API key to authenticate requests to the API. Make sure to keep
              it secure and never share it publicly.
            </p>
          </div>
          <div className="mt-5">
            <label className="block text-sm font-medium text-gray-700">
              API Key
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <input
                  type="text"
                  value={apiKey}
                  readOnly
                  className="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <Button
                type="button"
                variant="outline"
                onClick={handleCopyKey}
                className="-ml-px rounded-none"
              >
                <Copy className="h-4 w-4" />
              </Button>
              <Button
                type="button"
                variant="outline"
                onClick={handleRegenerateKey}
                disabled={isRegenerating}
                className="-ml-px rounded-r-md"
              >
                {isRegenerating ? (
                  <RefreshCw className="h-4 w-4 animate-spin" />
                ) : (
                  'Regenerate'
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Webhooks
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Configure webhook endpoints to receive real-time updates about events
              in your account.
            </p>
          </div>
          <div className="mt-5">
            <Button variant="outline">
              Configure Webhooks
            </Button>
          </div>
        </div>
      </div>

      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            API Documentation
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Learn how to integrate with our API using our comprehensive
              documentation and examples.
            </p>
          </div>
          <div className="mt-5">
            <Button variant="outline">
              View Documentation
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}