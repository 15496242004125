import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { InvoiceList } from './components/InvoiceList';
import { InvoiceForm } from './components/InvoiceForm';

export function Invoices() {
  const [isCreating, setIsCreating] = useState(false);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Invoices</h1>
        <Button onClick={() => setIsCreating(true)}>Create Invoice</Button>
      </div>

      {isCreating ? (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Create New Invoice</h2>
          <InvoiceForm
            onSubmit={async (data) => {
              // TODO: Implement invoice creation
              setIsCreating(false);
            }}
          />
        </div>
      ) : (
        <InvoiceList />
      )}
    </div>
  );
}