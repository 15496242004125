import { Routes, Route, Navigate } from 'react-router-dom';
import { ProtectedRoute } from '@/components/ProtectedRoute';
import { Layout } from '@/components/layout';
import { Dashboard } from '@/features/dashboard';
import { LoginPage } from '@/features/auth/LoginPage';
import { RegisterPage } from '@/features/auth/RegisterPage';
import { AdminRoutes } from '@/admin/routes';

// Import Function components
import { Jobs } from '@/features/functions/jobs';
import { Schedule } from '@/features/functions/schedule';
import { Estimates } from '@/features/functions/estimates';
import { Invoices } from '@/features/functions/invoices';
import { TimeTracking } from '@/features/functions/time-tracking';
import { Inventory } from '@/features/functions/inventory';
import { Purchases } from '@/features/functions/purchases';
import { Maintenance } from '@/features/maintenance';
import { Settings } from '@/features/settings';
import { Profile } from '@/features/profile';

export default function AppRoutes() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />

      {/* Admin Routes */}
      <Route path="/admin/*" element={
        <ProtectedRoute>
          <AdminRoutes />
        </ProtectedRoute>
      } />

      {/* Protected Routes */}
      <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
        <Route path="/" element={<Dashboard />} />
        
        {/* Function Routes */}
        <Route path="/jobs/*" element={<Jobs />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/estimates/*" element={<Estimates />} />
        <Route path="/invoices/*" element={<Invoices />} />
        <Route path="/time-tracking" element={<TimeTracking />} />
        <Route path="/inventory/*" element={<Inventory />} />
        <Route path="/purchases/*" element={<Purchases />} />
        
        {/* Other Routes */}
        <Route path="/maintenance/*" element={<Maintenance />} />
        <Route path="/settings/*" element={<Settings />} />
        <Route path="/profile/*" element={<Profile />} />
      </Route>

      {/* Redirect any unknown routes to login */}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}