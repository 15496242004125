import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { JobList } from './components/JobList';
import { JobForm } from './components/JobForm';
import { trpc } from '@/utils/trpc';

export function Jobs() {
  const [isCreating, setIsCreating] = useState(false);
  const createMutation = trpc.jobs.create.useMutation();
  const utils = trpc.useContext();

  const handleSubmit = async (data: any) => {
    try {
      await createMutation.mutateAsync(data);
      utils.jobs.list.invalidate();
      setIsCreating(false);
    } catch (error) {
      console.error('Failed to create job:', error);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Jobs</h1>
        <Button onClick={() => setIsCreating(true)}>Create Job</Button>
      </div>

      {isCreating ? (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Create New Job</h2>
          <JobForm
            onSubmit={handleSubmit}
            onCancel={() => setIsCreating(false)}
          />
        </div>
      ) : (
        <JobList />
      )}
    </div>
  );
}