import { useEffect, useState } from 'react';
import { CustomerCard } from './CustomerCard';
import { supabase } from '@/lib/auth';
import { Button } from '@/components/ui/button';

interface CustomerListProps {
  onEdit: (customer: any) => void;
}

export function CustomerList({ onEdit }: CustomerListProps) {
  const [customers, setCustomers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);  
  if (!user) {
    return null;
  }

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        const { data, error: fetchError } = await supabase
          .from('customers')
          .select('*')
          .eq('organization_id', user.user_metadata.organization_id)
          .order('name');

        if (fetchError) throw fetchError;
        setCustomers(data || []);
      } catch (err: any) {
        console.error('Failed to fetch customers:', err);
        setError(err.message || 'Failed to load customers');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  const handleDelete = async (id: string) => {
    try {
      const { error: deleteError } = await supabase
        .from('customers')
        .delete()
        .eq('id', id);

      if (deleteError) throw deleteError;

      setCustomers(customers.filter(customer => customer.id !== id));
    } catch (err: any) {
      console.error('Failed to delete customer:', err);
      setError(err.message || 'Failed to delete customer');
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-gray-500">Loading customers...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-md">
        <p>{error}</p>
        <Button 
          variant="outline" 
          className="mt-2"
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </div>
    );
  }

  if (!customers.length) {
    return (
      <div className="bg-white rounded-lg shadow p-6 text-center">
        <p className="text-gray-500">No customers found. Add your first customer to get started.</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {customers.map((customer) => (
        <CustomerCard
          key={customer.id}
          customer={customer}
          onEdit={onEdit}
          onDelete={handleDelete}
        />
      ))}
    </div>
  );
}