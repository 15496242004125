import { Routes, Route } from 'react-router-dom';
import { AdminLayout } from '../components/AdminLayout';
import { OrganizationsPage } from '../pages/OrganizationsPage';
import { DatabasesPage } from '../pages/DatabasesPage';
import { SystemSettingsPage } from '../pages/SystemSettingsPage';
import { ActivityLogsPage } from '../pages/ActivityLogsPage';

export function AdminRoutes() {
  return (
    <AdminLayout>
      <Routes>
        <Route path="/organizations" element={<OrganizationsPage />} />
        <Route path="/databases" element={<DatabasesPage />} />
        <Route path="/settings" element={<SystemSettingsPage />} />
        <Route path="/logs" element={<ActivityLogsPage />} />
      </Routes>
    </AdminLayout>
  );
}