import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { 
  LayoutDashboard, 
  Calendar, 
  ClipboardList, 
  FileText, 
  ShoppingCart, 
  Clock, 
  Package2, 
  Settings,
  Wrench
} from 'lucide-react';

export function Sidebar() {
  const location = useLocation();

  const navigation = [
    { name: 'Dashboard', href: '/', icon: LayoutDashboard },
    {
      name: 'Functions',
      items: [
        { name: 'Jobs', href: '/jobs', icon: ClipboardList },
        { name: 'Schedule', href: '/schedule', icon: Calendar },
        { name: 'Estimates', href: '/estimates', icon: FileText },
        { name: 'Invoices', href: '/invoices', icon: FileText },
        { name: 'Purchases', href: '/purchases', icon: ShoppingCart },
        { name: 'Time Tracking', href: '/time-tracking', icon: Clock },
        { name: 'Inventory', href: '/inventory', icon: Package2 },
      ],
    },
    { name: 'Maintenance', href: '/maintenance', icon: Wrench },
    { name: 'Settings', href: '/settings', icon: Settings },
  ];

  const isActiveRoute = (href: string) => {
    if (href === '/') {
      return location.pathname === href;
    }
    return location.pathname.startsWith(href);
  };

  return (
    <div className="w-64 bg-white border-r border-gray-200 h-full overflow-y-auto">
      <nav className="flex flex-col p-4 space-y-4">
        {navigation.map((item) => (
          item.items ? (
            <div key={item.name} className="space-y-2">
              <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
                {item.name}
              </h3>
              <div className="space-y-1">
                {item.items.map((subItem) => {
                  const isActive = isActiveRoute(subItem.href);
                  const Icon = subItem.icon;
                  return (
                    <Link
                      key={subItem.name}
                      to={subItem.href}
                      className={cn(
                        "flex items-center px-3 py-2 text-sm font-medium rounded-md",
                        isActive
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                      )}
                    >
                      <Icon className="h-5 w-5 mr-3" />
                      {subItem.name}
                    </Link>
                  );
                })}
              </div>
            </div>
          ) : (
            <Link
              key={item.name}
              to={item.href}
              className={cn(
                "flex items-center px-3 py-2 text-sm font-medium rounded-md",
                isActiveRoute(item.href)
                  ? "bg-gray-100 text-gray-900"
                  : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
              )}
            >
              {item.icon && <item.icon className="h-5 w-5 mr-3" />}
              {item.name}
            </Link>
          )
        ))}
      </nav>
    </div>
  );
}