import { useState } from 'react';
import { ProfileInfo } from './components/ProfileInfo';
import { ProfileStats } from './components/ProfileStats';
import { PasswordChange } from './components/PasswordChange';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

export function Profile() {
  const [activeTab, setActiveTab] = useState('info');

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Profile</h1>

      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList>
          <TabsTrigger value="info">Personal Information</TabsTrigger>
          <TabsTrigger value="stats">Activity & Statistics</TabsTrigger>
          <TabsTrigger value="security">Security</TabsTrigger>
        </TabsList>

        <TabsContent value="info" className="mt-6">
          <ProfileInfo />
        </TabsContent>

        <TabsContent value="stats" className="mt-6">
          <ProfileStats />
        </TabsContent>

        <TabsContent value="security" className="mt-6">
          <PasswordChange />
        </TabsContent>
      </Tabs>
    </div>
  );
}