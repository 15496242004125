import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { PurchaseList } from './components/PurchaseList';
import { PurchaseForm } from './components/PurchaseForm';
import { supabase } from '@/lib/auth';

export function Purchases() {
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (data: any) => {
    try {
      setError(null);

      const { data: user } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      const { data: po, error: createError } = await supabase
        .from('purchase_orders')
        .insert([{
          supplier_id: data.supplierId,
          expected_delivery_date: data.expectedDeliveryDate,
          notes: data.notes,
          status: 'DRAFT',
          organization_id: user.user.user_metadata.organization_id
        }])
        .select()
        .single();

      if (createError) throw createError;

      // Insert purchase order items
      const { error: itemsError } = await supabase
        .from('purchase_order_items')
        .insert(
          data.items.map((item: any) => ({
            purchase_order_id: po.id,
            product_id: item.productId,
            quantity: item.quantity,
            price: item.price
          }))
        );

      if (itemsError) throw itemsError;

      setIsCreating(false);
    } catch (err: any) {
      console.error('Failed to create purchase order:', err);
      setError(err.message || 'Failed to create purchase order');
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Purchase Orders</h1>
        <Button onClick={() => setIsCreating(true)}>Create Purchase Order</Button>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-md">
          {error}
        </div>
      )}

      {isCreating ? (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Create New Purchase Order</h2>
          <PurchaseForm
            onSubmit={handleSubmit}
            onCancel={() => setIsCreating(false)}
          />
        </div>
      ) : (
        <PurchaseList />
      )}
    </div>
  );
}