import { useState } from 'react';
import { trpc } from '@/utils/trpc';
import { VehicleCard } from './VehicleCard';
import { Button } from '@/components/ui/button';
import { Truck } from 'lucide-react';

interface VehicleListProps {
  onEdit: (vehicle: any) => void;
}

export function VehicleList({ onEdit }: VehicleListProps) {
  const { data: vehicles, isLoading, error, refetch } = trpc.vehicles.list.useQuery(undefined, {
    retry: 1,
    refetchOnWindowFocus: false,
    onError: (err) => {
      console.error('Vehicle list query error:', err);
    }
  });

  const utils = trpc.useContext();
  
  const deleteMutation = trpc.vehicles.delete.useMutation({
    onSuccess: () => {
      utils.vehicles.list.invalidate();
    },
    onError: (err) => {
      console.error('Delete vehicle error:', err);
    }
  });

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync({ id });
    } catch (error) {
      console.error('Failed to delete vehicle:', error);
    }
  };

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-700">
        <p>Error loading vehicles: {error.message}</p>
        <Button 
          variant="outline" 
          className="mt-2"
          onClick={() => refetch()}
        >
          Retry
        </Button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-48">
        <div className="text-gray-500">Loading vehicles...</div>
      </div>
    );
  }

  if (!vehicles?.length) {
    return (
      <div className="bg-white rounded-lg shadow p-6 text-center">
        <Truck className="h-12 w-12 text-gray-400 mx-auto mb-4" />
        <p className="text-gray-500 mb-4">No vehicles found. Add your first vehicle to get started.</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {vehicles.map((vehicle) => (
        <VehicleCard
          key={vehicle.id}
          vehicle={vehicle}
          onEdit={onEdit}
          onDelete={handleDelete}
          onViewInventory={() => {/* TODO: Implement inventory view */}}
        />
      ))}
    </div>
  );
}