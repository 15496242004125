import { trpc } from '@/utils/trpc';
import { Button } from '@/components/ui/button';
import { format } from 'date-fns';
import { Edit, Send, Package, Trash2, X } from 'lucide-react';

interface PurchaseListProps {
  onEdit: (purchase: any) => void;
}

export function PurchaseList({ onEdit }: PurchaseListProps) {
  const { data: purchases, isLoading } = trpc.purchases.list.useQuery();
  const updateStatusMutation = trpc.purchases.updateStatus.useMutation();
  const deleteMutation = trpc.purchases.delete.useMutation();
  const utils = trpc.useContext();

  const handleStatusUpdate = async (id: string, status: string) => {
    try {
      await updateStatusMutation.mutateAsync({ id, status: status as any });
      utils.purchases.list.invalidate();
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation.mutateAsync({ id });
      utils.purchases.list.invalidate();
    } catch (error) {
      console.error('Failed to delete purchase order:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Supplier
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Expected Delivery
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Total
            </th>
            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {purchases?.map((purchase) => (
            <tr key={purchase.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">
                  {purchase.supplier.name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">
                  {format(new Date(purchase.createdAt), 'PP')}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">
                  {purchase.expectedDeliveryDate
                    ? format(new Date(purchase.expectedDeliveryDate), 'PP')
                    : '-'}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  purchase.status === 'DRAFT'
                    ? 'bg-gray-100 text-gray-800'
                    : purchase.status === 'SENT'
                    ? 'bg-blue-100 text-blue-800'
                    : purchase.status === 'RECEIVED'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800'
                }`}>
                  {purchase.status}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  ${purchase.items.reduce((sum, item) => sum + (item.quantity * item.price), 0).toFixed(2)}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <div className="flex justify-end space-x-2">
                  {purchase.status === 'DRAFT' && (
                    <>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => onEdit(purchase)}
                      >
                        <Edit className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleStatusUpdate(purchase.id, 'SENT')}
                      >
                        <Send className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleDelete(purchase.id)}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </>
                  )}
                  {purchase.status === 'SENT' && (
                    <>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleStatusUpdate(purchase.id, 'RECEIVED')}
                      >
                        <Package className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleStatusUpdate(purchase.id, 'CANCELLED')}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}