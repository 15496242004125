import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import { trpc } from '@/utils/trpc';
import { Plus, Trash2 } from 'lucide-react';

const pricingRuleSchema = z.object({
  productId: z.string(),
  type: z.enum(['FIXED', 'MARKUP_PERCENTAGE', 'MARKUP_AMOUNT']),
  value: z.number(),
});

const pricingGroupSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  description: z.string().optional(),
  rules: z.array(pricingRuleSchema),
});

type PricingGroupFormData = z.infer<typeof pricingGroupSchema>;

interface PricingGroupFormProps {
  initialData?: PricingGroupFormData;
  onSubmit: (data: PricingGroupFormData) => void;
  onCancel: () => void;
}

export function PricingGroupForm({ initialData, onSubmit, onCancel }: PricingGroupFormProps) {
  const { data: products } = trpc.products.list.useQuery();

  const { register, control, handleSubmit, formState: { errors } } = useForm<PricingGroupFormData>({
    resolver: zodResolver(pricingGroupSchema),
    defaultValues: initialData || {
      rules: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'rules',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Name
        </label>
        <input
          {...register('name')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        />
        {errors.name && (
          <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Description
        </label>
        <textarea
          {...register('description')}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Pricing Rules
        </label>
        <div className="space-y-4">
          {fields.map((field, index) => (
            <div key={field.id} className="flex gap-4 items-start">
              <div className="flex-1">
                <select
                  {...register(`rules.${index}.productId`)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value="">Select a product</option>
                  {products?.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-40">
                <select
                  {...register(`rules.${index}.type`)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value="FIXED">Fixed Price</option>
                  <option value="MARKUP_PERCENTAGE">Markup %</option>
                  <option value="MARKUP_AMOUNT">Markup Amount</option>
                </select>
              </div>

              <div className="w-32">
                <input
                  type="number"
                  step="0.01"
                  {...register(`rules.${index}.value`, { valueAsNumber: true })}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>

              <Button
                type="button"
                variant="ghost"
                size="icon"
                onClick={() => remove(index)}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          ))}
        </div>

        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={() => append({ productId: '', type: 'FIXED', value: 0 })}
          className="mt-2"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Rule
        </Button>
      </div>

      <div className="flex justify-end space-x-3">
        <Button type="button" variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit">
          {initialData ? 'Update Pricing Group' : 'Create Pricing Group'}
        </Button>
      </div>
    </form>
  );
}