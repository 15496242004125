import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { SupplierList } from './components/SupplierList';
import { SupplierForm } from './components/SupplierForm';
import { trpc } from '@/utils/trpc';

export function Suppliers() {
  const [isCreating, setIsCreating] = useState(false);
  const [editingSupplier, setEditingSupplier] = useState<any>(null);
  
  const createMutation = trpc.suppliers.create.useMutation();
  const updateMutation = trpc.suppliers.update.useMutation();
  const utils = trpc.useContext();

  const handleSubmit = async (data: any) => {
    try {
      if (editingSupplier) {
        await updateMutation.mutateAsync({
          id: editingSupplier.id,
          data,
        });
      } else {
        await createMutation.mutateAsync(data);
      }
      utils.suppliers.list.invalidate();
      setIsCreating(false);
      setEditingSupplier(null);
    } catch (error) {
      console.error('Failed to save supplier:', error);
    }
  };

  const handleEdit = (supplier: any) => {
    setEditingSupplier(supplier);
    setIsCreating(true);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Suppliers</h1>
        <Button onClick={() => {
          setEditingSupplier(null);
          setIsCreating(true);
        }}>
          Add Supplier
        </Button>
      </div>

      {isCreating ? (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">
            {editingSupplier ? 'Edit Supplier' : 'Add New Supplier'}
          </h2>
          <SupplierForm
            initialData={editingSupplier}
            onSubmit={handleSubmit}
            onCancel={() => {
              setIsCreating(false);
              setEditingSupplier(null);
            }}
          />
        </div>
      ) : (
        <SupplierList onEdit={handleEdit} />
      )}
    </div>
  );
}