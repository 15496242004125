import { trpc } from '@/utils/trpc';
import { Button } from '@/components/ui/button';
import { format } from 'date-fns';
import { FileText, Send, Check, X, Trash2 } from 'lucide-react';

export function EstimateList() {
  const { data: estimates, isLoading } = trpc.estimates.list.useQuery();
  const updateStatusMutation = trpc.estimates.updateStatus.useMutation();
  const deleteMutation = trpc.estimates.delete.useMutation();
  const convertToJobMutation = trpc.estimates.convertToJob.useMutation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const getStatusBadgeColor = (status: string) => {
    switch (status) {
      case 'DRAFT': return 'bg-gray-100 text-gray-800';
      case 'SENT': return 'bg-blue-100 text-blue-800';
      case 'ACCEPTED': return 'bg-green-100 text-green-800';
      case 'REJECTED': return 'bg-red-100 text-red-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Number
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Customer
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Total
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {estimates?.map((estimate) => (
            <tr key={estimate.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">
                  {estimate.number}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {estimate.customer.name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {format(new Date(estimate.createdAt), 'PP')}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  ${estimate.total.toFixed(2)}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeColor(estimate.status)}`}>
                  {estimate.status}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <div className="flex space-x-2">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => {/* TODO: View PDF */}}
                  >
                    <FileText className="h-4 w-4" />
                  </Button>

                  {estimate.status === 'DRAFT' && (
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => updateStatusMutation.mutate({
                        id: estimate.id,
                        status: 'SENT'
                      })}
                    >
                      <Send className="h-4 w-4" />
                    </Button>
                  )}

                  {estimate.status === 'SENT' && (
                    <>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => updateStatusMutation.mutate({
                          id: estimate.id,
                          status: 'ACCEPTED'
                        })}
                      >
                        <Check className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => updateStatusMutation.mutate({
                          id: estimate.id,
                          status: 'REJECTED'
                        })}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </>
                  )}

                  {estimate.status === 'ACCEPTED' && !estimate.jobId && (
                    <Button
                      variant="ghost"
                      onClick={() => convertToJobMutation.mutate({ id: estimate.id })}
                    >
                      Convert to Job
                    </Button>
                  )}

                  {estimate.status === 'DRAFT' && (
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => deleteMutation.mutate({ id: estimate.id })}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}