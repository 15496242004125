import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Products } from './products';
import { Warehouses } from './warehouses';
import { Vehicles } from './vehicles';
import { Equipment } from './equipment';
import { Customers } from './customers';
import { Suppliers } from './suppliers';
import { Pricing } from './pricing';

export function Maintenance() {
  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Maintenance</h1>

      <Tabs defaultValue="customers">
        <TabsList>
          <TabsTrigger value="customers">Customers</TabsTrigger>
          <TabsTrigger value="suppliers">Suppliers</TabsTrigger>
          <TabsTrigger value="products">Products</TabsTrigger>
          <TabsTrigger value="pricing">Pricing</TabsTrigger>
          <TabsTrigger value="warehouses">Warehouses</TabsTrigger>
          <TabsTrigger value="vehicles">Vehicles</TabsTrigger>
          <TabsTrigger value="equipment">Equipment</TabsTrigger>
        </TabsList>

        <TabsContent value="customers" className="mt-6">
          <Customers />
        </TabsContent>

        <TabsContent value="suppliers" className="mt-6">
          <Suppliers />
        </TabsContent>

        <TabsContent value="products" className="mt-6">
          <Products />
        </TabsContent>

        <TabsContent value="pricing" className="mt-6">
          <Pricing />
        </TabsContent>

        <TabsContent value="warehouses" className="mt-6">
          <Warehouses />
        </TabsContent>

        <TabsContent value="vehicles" className="mt-6">
          <Vehicles />
        </TabsContent>

        <TabsContent value="equipment" className="mt-6">
          <Equipment />
        </TabsContent>
      </Tabs>
    </div>
  );
}