import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { WarehouseList } from './components/WarehouseList';
import { WarehouseForm } from './components/WarehouseForm';
import { trpc } from '@/utils/trpc';

export function Warehouses() {
  const [isCreating, setIsCreating] = useState(false);
  const createMutation = trpc.warehouses.create.useMutation();
  const utils = trpc.useContext();

  const handleCreate = async (data: any) => {
    try {
      await createMutation.mutateAsync(data);
      utils.warehouses.list.invalidate();
      setIsCreating(false);
    } catch (error) {
      console.error('Failed to create warehouse:', error);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Warehouses</h1>
        <Button onClick={() => setIsCreating(true)}>Add Warehouse</Button>
      </div>

      {isCreating ? (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Create New Warehouse</h2>
          <WarehouseForm onSubmit={handleCreate} />
        </div>
      ) : (
        <WarehouseList />
      )}
    </div>
  );
}