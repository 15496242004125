import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { EquipmentList } from './components/EquipmentList';
import { EquipmentForm } from './components/EquipmentForm';
import { trpc } from '@/utils/trpc';

export function Equipment() {
  const [isCreating, setIsCreating] = useState(false);
  const [editingEquipment, setEditingEquipment] = useState<any>(null);
  
  const createMutation = trpc.equipment.create.useMutation();
  const updateMutation = trpc.equipment.update.useMutation();
  const utils = trpc.useContext();

  const handleSubmit = async (data: any) => {
    try {
      if (editingEquipment) {
        await updateMutation.mutateAsync({
          id: editingEquipment.id,
          data,
        });
      } else {
        await createMutation.mutateAsync(data);
      }
      utils.equipment.list.invalidate();
      setIsCreating(false);
      setEditingEquipment(null);
    } catch (error) {
      console.error('Failed to save equipment:', error);
    }
  };

  const handleEdit = (equipment: any) => {
    setEditingEquipment(equipment);
    setIsCreating(true);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Equipment</h1>
        <Button onClick={() => {
          setEditingEquipment(null);
          setIsCreating(true);
        }}>
          Add Equipment
        </Button>
      </div>

      {isCreating ? (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">
            {editingEquipment ? 'Edit Equipment' : 'Add New Equipment'}
          </h2>
          <EquipmentForm
            initialData={editingEquipment}
            onSubmit={handleSubmit}
            onCancel={() => {
              setIsCreating(false);
              setEditingEquipment(null);
            }}
          />
        </div>
      ) : (
        <EquipmentList onEdit={handleEdit} />
      )}
    </div>
  );
}