import { CalendarDays, Briefcase, ClipboardCheck } from 'lucide-react';

export function Dashboard() {
  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Quick Stats */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center space-x-3">
            <Briefcase className="h-8 w-8 text-blue-500" />
            <div>
              <h3 className="text-lg font-semibold">Active Jobs</h3>
              <p className="text-2xl font-bold">12</p>
            </div>
          </div>
        </div>
        
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center space-x-3">
            <CalendarDays className="h-8 w-8 text-green-500" />
            <div>
              <h3 className="text-lg font-semibold">Today's Schedule</h3>
              <p className="text-2xl font-bold">5</p>
            </div>
          </div>
        </div>
        
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center space-x-3">
            <ClipboardCheck className="h-8 w-8 text-purple-500" />
            <div>
              <h3 className="text-lg font-semibold">Pending Estimates</h3>
              <p className="text-2xl font-bold">3</p>
            </div>
          </div>
        </div>
      </div>

      {/* Calendar Overview */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4">Today's Schedule</h2>
        {/* Calendar component will go here */}
      </div>

      {/* Recent Activity */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4">Recent Activity</h2>
        {/* Activity feed will go here */}
      </div>
    </div>
  );
}