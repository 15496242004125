import { TimeTracker } from './components/TimeTracker';
import { TimeEntryList } from './components/TimeEntryList';

export function TimeTracking() {
  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Time Tracking</h1>
      
      <TimeTracker />
      
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Time Entries</h2>
        <TimeEntryList />
      </div>
    </div>
  );
}