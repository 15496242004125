import { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { PricingGroups } from './components/PricingGroups';
import { CustomerPricing } from './components/CustomerPricing';
import { SupplierPricing } from './components/SupplierPricing';

export function Pricing() {
  const [activeTab, setActiveTab] = useState('groups');

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Pricing Management</h1>

      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList>
          <TabsTrigger value="groups">Pricing Groups</TabsTrigger>
          <TabsTrigger value="customers">Customer Pricing</TabsTrigger>
          <TabsTrigger value="suppliers">Supplier Pricing</TabsTrigger>
        </TabsList>

        <TabsContent value="groups" className="mt-6">
          <PricingGroups />
        </TabsContent>

        <TabsContent value="customers" className="mt-6">
          <CustomerPricing />
        </TabsContent>

        <TabsContent value="suppliers" className="mt-6">
          <SupplierPricing />
        </TabsContent>
      </Tabs>
    </div>
  );
}