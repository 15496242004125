import { Vehicle } from '@prisma/client';
import { Button } from '@/components/ui/button';
import { Edit, Trash2, Truck, Package } from 'lucide-react';

interface VehicleCardProps {
  vehicle: Vehicle;
  onEdit: (vehicle: Vehicle) => void;
  onDelete: (id: string) => void;
  onViewInventory: (id: string) => void;
}

export function VehicleCard({
  vehicle,
  onEdit,
  onDelete,
  onViewInventory,
}: VehicleCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6 space-y-4">
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-green-50 rounded-lg">
            <Truck className="h-6 w-6 text-green-600" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-900">
              {vehicle.name}
            </h3>
            {vehicle.licensePlate && (
              <p className="text-sm text-gray-500">
                License Plate: {vehicle.licensePlate}
              </p>
            )}
          </div>
        </div>
        <div className="flex space-x-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onEdit(vehicle)}
          >
            <Edit className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onDelete(vehicle.id)}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <div className="pt-4 border-t border-gray-200">
        <Button
          variant="outline"
          className="w-full"
          onClick={() => onViewInventory(vehicle.id)}
        >
          <Package className="h-4 w-4 mr-2" />
          View Inventory
        </Button>
      </div>
    </div>
  );
}