import { Equipment } from '@prisma/client';
import { Button } from '@/components/ui/button';
import { Edit, Trash2, Wrench } from 'lucide-react';
import { StatusBadge } from './StatusBadge';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface EquipmentCardProps {
  equipment: Equipment;
  onEdit: (equipment: Equipment) => void;
  onDelete: (id: string) => void;
  onStatusChange: (id: string, status: Equipment['status']) => void;
}

export function EquipmentCard({
  equipment,
  onEdit,
  onDelete,
  onStatusChange,
}: EquipmentCardProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6 space-y-4">
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-purple-50 rounded-lg">
            <Tool className="h-6 w-6 text-purple-600" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-900">
              {equipment.name}
            </h3>
            {equipment.model && (
              <p className="text-sm text-gray-500">
                Model: {equipment.model}
              </p>
            )}
          </div>
        </div>
        <div className="flex space-x-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onEdit(equipment)}
          >
            <Edit className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onDelete(equipment.id)}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <div className="space-y-2">
        {equipment.serialNumber && (
          <p className="text-sm text-gray-500">
            Serial Number: {equipment.serialNumber}
          </p>
        )}
        {equipment.manufacturer && (
          <p className="text-sm text-gray-500">
            Manufacturer: {equipment.manufacturer}
          </p>
        )}
      </div>

      <div className="pt-4 border-t border-gray-200 space-y-4">
        <div className="flex justify-between items-center">
          <span className="text-sm font-medium text-gray-500">Status</span>
          <StatusBadge status={equipment.status} />
        </div>

        <Select
          value={equipment.status}
          onValueChange={(value: Equipment['status']) => 
            onStatusChange(equipment.id, value)
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Select status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="AVAILABLE">Available</SelectItem>
            <SelectItem value="IN_USE">In Use</SelectItem>
            <SelectItem value="MAINTENANCE">Maintenance</SelectItem>
            <SelectItem value="OUT_OF_SERVICE">Out of Service</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}